export default function({ StreamActions }: { StreamActions: any }) {
  StreamActions.customEvent = function() {
    const name = this.getAttribute("name")
    if (!name) {
      return
    }
    const event = new Event(name)
    window.dispatchEvent(event)
    // If you want to send the event somewhere besides the window
    // document.getElementById(this.target).dispatchEvent(event)
  }
}
