import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sidebar"]
  readonly sidebarTarget!: HTMLElement

  connect() {
    if (!("triggerSidebar" in this.sidebarTarget.dataset) || this.sidebarTarget.dataset.triggerSidebar === "true") {
      setTimeout(() => {
        if (document.querySelector("nmx-sidenav-drawer")) (document.querySelector("nmx-sidenav-drawer") as HTMLElement).style.zIndex = "-1"
        this.sidebarTarget.style.right = "0px"
      }, 0)
    }

    window.addEventListener("keydown", this.handleKeydownEvent)
  }

  disconnect() {
    window.removeEventListener("keydown", this.handleKeydownEvent)
  }

  handleKeydownEvent = (event: KeyboardEvent) => {
    if (event.key === "Escape") { this.close() }
  }

  close() {
    const width = this.element.scrollWidth

    //  When the transition ends, remove the whole overlay
    this.sidebarTarget.addEventListener("transitionend", () => {
      this.element.remove();
      if (document.querySelector("nmx-sidenav-drawer")) (document.querySelector("nmx-sidenav-drawer") as HTMLElement).style.zIndex = "1"
    })

    //  Start the transition to slide the sidebar off to the right
    this.sidebarTarget.style.right = `-${width}px`
  }
}
