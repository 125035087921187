import { Controller } from "@hotwired/stimulus"
import { computePosition, autoPlacement, shift, offset, arrow } from "@floating-ui/dom"

export default class extends Controller {
  static targets = ["container", "arrow", "popoverContent", "backdrop"]
  declare readonly containerTarget: HTMLElement
  declare readonly arrowTarget: HTMLElement
  declare readonly popoverContentTarget: HTMLElement
  declare readonly backdropTarget: HTMLElement

  declare readonly hasPopoverContentTarget: boolean

  triggerClass = "popover__trigger"
  arrowShift = "-12px"

  //  Called when the trigger is invoked
  setTrigger(e: Event) {
    if (this.isOpen()) this.close()

    const currentTarget = e.currentTarget as HTMLElement

    document.querySelectorAll(`.${this.triggerClass}`).forEach(el => el.classList.remove(this.triggerClass))
    //  Set a data-item on the modal wrapper to say which element is the trigger
    this.containerTarget.dataset.trigger = currentTarget.getAttribute("id") ?? ""
    currentTarget.classList.add(this.triggerClass)
  }

  copyTemplate(source: HTMLElement, triggerId: string) {
    if (!triggerId) throw new Error("trigger node should have an id attribute")

    this.contentTarget().innerHTML = source.innerHTML
    this.containerTarget.dataset.trigger = triggerId
  }

  handleLocalTemplate(e: Event) {
    const template = this.template(e)
    const triggerId = (e.currentTarget as HTMLElement).id
    if (template) {
      this.setTrigger(e)
      this.copyTemplate(template, triggerId)
    }
  }

  template(e: Event) {
    const templateId = this.templateId(e)
    if (templateId) return document.getElementById(templateId) as HTMLElement

    // try finding a <template> in the trigger subtree
    if (e.currentTarget !== window) {
      return (e.currentTarget as HTMLElement).querySelector("template")
    }
  }

  templateId(e: Event) {
    return (e.target as HTMLElement).dataset?.popoverTemplateId
  }

  trigger() {
    return document.getElementById(this.containerTarget?.dataset?.trigger ?? "")
  }

  contentTarget() {
    return (this.hasPopoverContentTarget ? this.popoverContentTarget : document.getElementById("popover")) as HTMLElement
  }

  isOpen() {
    return this.arrowTarget.classList.contains("hidden")
  }

  hasBackdrop() {
    return (this.trigger() as HTMLElement)?.dataset?.popoverBackdrop !== undefined
  }

  isTooltip() {
    return (this.trigger() as HTMLElement)?.dataset?.tooltip !== undefined
  }

  //  Called when the popover is loaded (turbo-stream custom event popoverOpen)
  open(e: Event) {
    this.handleLocalTemplate(e)

    if (this.hasBackdrop()) {
      this.backdropTarget.classList.remove("hidden")
    }

    const arrowElement = this.arrowTarget
    arrowElement.classList.remove("hidden")
    if (this.hasPopoverContentTarget) this.popoverContentTarget.classList.remove("hidden")
    this.reposition(e)
  }

  reposition(e: Event) {
    const popover = this.containerTarget
    const arrowElement = this.arrowTarget
    if (arrowElement.classList.contains("hidden")) {
      return
    }

    const triggerID = this.containerTarget.dataset.trigger || ""
    const triggerElement = document.getElementById(triggerID)
    if (!triggerElement) {
      return
    }

    const placement = triggerElement.dataset.placement

    const where = placement ? null : autoPlacement()

    computePosition(triggerElement, popover, {
      middleware: [
        offset(5),
        where,
        shift({ padding: 5 }),
        arrow({ element: arrowElement })
      ],
      // @ts-ignore
      placement
    }).then(({ x, y, placement, middlewareData }) => {
      Object.assign(popover.style, {
        left: `${x}px`,
        top: `${y}px`,
        position: "absolute"
      })

      // @ts-ignore
      const { x: arrowX, y: arrowY } = middlewareData.arrow

      const staticSide = {
        top: "bottom",
        right: "left",
        bottom: "top",
        left: "right"
      }[placement.split("-")[0]]

      const rotate = {
        right: -45,
        bottom: 45,
        left: 135,
        top: 225
      }[placement.split("-")[0]]

      Object.assign(arrowElement.style, {
        left: arrowX != null ? `${arrowX}px` : "",
        top: arrowY != null ? `${arrowY}px` : "",
        right: "",
        bottom: "",
        // @ts-ignore
        [staticSide]: this.arrowShift,
        transform: `rotate(${rotate}deg)`
      })
    })
  }

  close() {
    if (this.hasPopoverContentTarget) {
      this.popoverContentTarget.classList.add("hidden")
    } else {
      const popover = this.contentTarget()
      if (!popover) {
        return
      }
      popover.innerHTML = ""
      this.containerTarget.dataset.trigger = ""
      document.querySelectorAll(`.${this.triggerClass}`).forEach(el => el.classList.remove(this.triggerClass))
    }
    this.arrowTarget.classList.add("hidden")
    if (this.backdropTarget) { this.backdropTarget.classList.add("hidden") }
  }
}
