import { Controller } from "@hotwired/stimulus"
import * as am5 from "@amcharts/amcharts5"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5index from "@amcharts/amcharts5/index";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

export default class extends Controller {
  connect() {
    // Create root element
    const root = am5.Root.new(this.element as HTMLElement)
    root._logo?.dispose()

    const content = document.querySelector("html") as HTMLElement
    const dark = content?.classList.contains("dark")

    // Set themes
    root.setThemes([
      am5themes_Animated.new(root)
    ])

    // Create chart
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/
    let chart = root.container.children.push(am5percent.PieChart.new(root, {
      layout: root.verticalLayout,
      innerRadius: am5.percent(70)
    }));

    // Create series
    // https://www.amcharts.com/docs/v5/charts/percent-charts/pie-chart/#Series
    let series = chart.series.push(am5percent.PieSeries.new(root, {
      valueField: "value",
      categoryField: "category",
      alignLabels: false,
      tooltip: am5.Tooltip.new(root, {
        pointerOrientation: "horizontal",
        labelText: "[fontSize:12px;]{category}[/]"
      })
    }));

    series.get("colors")!.set("colors", [
      am5.color(0x6A7280),
      am5.color(0x90C058),
      am5.color(0xFFC600),
      am5.color(0xFE4E59),
    ]);

    series.ticks.template.set("forceHidden", true);
    series.labels.template.set("forceHidden", true);

    series.slices.template.setAll({
      cornerRadius: 8,
      strokeWidth: 2,
      stroke: am5.Color.fromString(dark ? "#000" : "#f0f3f7")
    })

    // Set data
    const data = JSON.parse((this.element as HTMLElement).dataset.data || "[]")

    const formattedData = [
      { category: "No data", value: data.filter((bucket: any) => (bucket["state"] == null)).length },
      { category: "Good", value: data.filter((bucket: any) => (bucket["state"] == "0")).length },
      { category: "Warning", value: data.filter((bucket: any) => (bucket["state"] == "1")).length },
      { category: "Alert", value: data.filter((bucket: any) => (bucket["state"] == "2")).length },
    ]
    series.data.setAll(formattedData)

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/#Initial_animation
    chart.appear(300, 100)
  }
}
