import { Controller } from "@hotwired/stimulus"

export default class ToggleHiddenController extends Controller {
  static targets = ["toggleable"]
  readonly toggleableTargets!: HTMLElement[]

  toggle() {
    this.toggleableTargets.forEach((element: HTMLElement) => {
      if (element.classList.contains("expandable__wrapper")) {
        // hide first, show last
        if (element.style.visibility === "hidden") {
          element.addEventListener("transitionend", () => {
            element.style.visibility = "initial"
          }, { once: true })
        } else {
            element.style.visibility = "hidden"
        }
        element.style.gridTemplateRows = (element.style.gridTemplateRows === "0fr" ? "1fr" : "0fr")
      } else {
        element.classList.toggle("hidden")
      }
    })

    this.element.classList.toggle("toggleable-hidden")
  }
}
