import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["observee"]
  readonly observeeTargets!: HTMLElement[]

  observeeTargetConnected(element: HTMLElement) {
    const callback = (mutationsList: MutationRecord[]) => {
      const eventTarget = document.querySelector(element.dataset.eventTarget || "") || element
      const event = new CustomEvent("classlist-changed");
      eventTarget.dispatchEvent(event);
    }

    const observer = new MutationObserver(callback);
    const config = { attributes: true, attributeFilter: ['class'] };
    observer.observe(element, config);
  }
}