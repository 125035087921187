import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import { Turbo } from "@hotwired/turbo-rails"
import "./channels"
import "./controllers"
import "./locales"
import extendTurbo from "../../engines/nc/app/javascript/nc/custom_actions/dispatch_event"

extendTurbo(Turbo)

Rails.start()
